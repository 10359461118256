import React from "react";
import { Dashboard as DashboardIcon, People as PeopleIcon, CalendarMonth as CalendarMonthIcon, RequestQuote as RequestQuoteIcon, Construction as ConstructionIcon, VerifiedUser as VerifiedUserIcon, Equalizer as EqualizerIcon, SupportAgent as SupportAgentIcon, Layers as LayersIcon, Notifications as NotificationsIcon, AccountCircle as AccountCircleIcon } from "@mui/icons-material";
import { hasPermission } from '../utility/permissions';

const MenuGenerator = ({ user }) => {
  // check permissions for menu items
  const leadsView = hasPermission(user, 'leads', 'view');
  const calendarView = hasPermission(user, 'calendar', 'view');
  const proposalsView = hasPermission(user, 'proposals', 'view');
  const jobsView = hasPermission(user, 'jobs', 'view');
  const warrantyView = hasPermission(user, 'warranty', 'view');
  const reportsView = hasPermission(user, 'reports', 'view');

  const menuItems = [
    { name: "Dashboard", path: "/dashboard", icon: <DashboardIcon /> },
    ...(leadsView ? [{ name: "Leads", path: "/leads", icon: <PeopleIcon /> }] : []),
    ...(calendarView ? [{ name: "Calendar", path: "/calendar", icon: <CalendarMonthIcon /> }] : []),
    ...(proposalsView ? [{ name: "Proposals", path: "/proposals", icon: <RequestQuoteIcon /> }] : []),
    ...(jobsView ? [{ name: "Jobs", path: "/jobs", icon: <ConstructionIcon /> }] : []),
    ...(warrantyView ? [{ name: "Warranty", path: "/warranty", icon: <VerifiedUserIcon /> }] : []),
    ...(reportsView ? [{ name: "Reports", path: "/reports", icon: <EqualizerIcon /> }] : []),
    { name: "Support", path: "/support", icon: <SupportAgentIcon /> },
    { name: "Test", path: "/test", icon: <LayersIcon /> },
    { name: "Alerts", path: "/alerts", icon: <NotificationsIcon /> },
    { name: "Account", path: "/account", icon: <AccountCircleIcon /> },
  ];

  return menuItems;
};

export default MenuGenerator;
