import React, { useState } from 'react';
import { TextField } from "@mui/material";

const EmailInput = ({ id, label, value, required, onBlur }) => {
  const [email, setEmail] = useState(value || '');
  const [emailError, setEmailError] = useState('');
  const [blurred, setBlurred] = useState(false); // Track if the input has been blurred

  const validateEmail = (email) => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    return isValid;
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleEmailBlur = () => {
    setBlurred(true); // Mark the input as blurred
    const isValid = validateEmail(email);
    if (!isValid) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
    if (onBlur) onBlur(isValid); // Pass the validation result to the parent component
  };

  return (
    <TextField
      id={id}
      label={label}
      value={email}
      required={required}
      onChange={handleEmailChange}
      onBlur={handleEmailBlur}
      error={blurred && !!emailError} // Show error only if input has been blurred
      helperText={blurred ? emailError : ''}
      size='small'
      fullWidth
    />
  );
};

export default EmailInput;
