import React from "react";
import MainLayout from "../../layout/MainLayout";
import { Box, Paper, Typography } from "@mui/material";
import Logo from "../../assets/Logo";

const Proposals = () => {
  return (
    <MainLayout title="Proposals">
      <Paper variant="outlined"  
        sx={{
          py: 1, 
          px: 2, 
          height: '100%' 
        }}
      >
        <Box 
          sx={{
            display: 'flex', 
            flexDirection: "column",
            alignItems: 'center', 
            justifyContent: 'center', 
            height: '100%'
          }}
        >
          <Logo width={"150px"} />
          <Typography variant="h3" fontWeight={'bold'} sx={{p:1, display: 'block', pt: 3 }}>
              Coming soon
          </Typography>
        </Box>
      </Paper>
    </MainLayout>
  );
};

export default Proposals;
