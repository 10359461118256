import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { getFirestore, doc, onSnapshot, getDoc } from 'firebase/firestore';
import { auth } from "../../config/FirebaseConfig";

function useSignIn() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  let profileListenerUnsubscribe = null;

  const signInAndFetchUserData = async (email, password) => {
    try {
      setLoading(true);
      setError(null);
      
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      // Store authentication token in session storage
      sessionStorage.setItem("authToken", userCredential.accessToken);

      // Fetch user data
      const userData = await fetchUserData(user);

      // Check if user data is available
      if (userData) {
        // Start real-time listener for user profile changes
        startProfileListener(user.uid);

        sessionStorage.setItem("userData", JSON.stringify(userData));
        navigate("/dashboard"); // Navigate to dashboard
      } else {
        setError("user-data-not-found");
      }
    } catch (error) {
      setError(error.code);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserData = async (user) => {
    try {
      const db = getFirestore();
      const userRef = doc(db, "users", user.uid);
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        console.error("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      return null;
    }
  };

  // Function to start real-time listener for user profile changes
  const startProfileListener = (userId) => {
    const db = getFirestore();
    const userRef = doc(db, "users", userId);

    profileListenerUnsubscribe = onSnapshot(userRef, (doc) => {
      if (doc.exists()) {
        const userData = doc.data();
        sessionStorage.setItem("userData", JSON.stringify(userData));
      } else {
        console.log("User profile document does not exist");
      }
    });
  };

  // Function to stop real-time listener for user profile changes
  const stopProfileListener = () => {
    if (profileListenerUnsubscribe) {
      profileListenerUnsubscribe();
    }
  };

  const signOutUser = async () => {
    try {
      await auth.signOut();
      stopProfileListener(); // Remove the listener when user signs out
      sessionStorage.removeItem("authToken");
      sessionStorage.removeItem("userData");
      navigate("/signin");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  // Function to get error message based on error type
  const getErrorMessage = (errorType) => {
    switch (errorType) {
      case "auth/missing-password":
        return "Missing password. Please try again.";
      case "auth/invalid-credential":
        return "Invalid email or password. Please try again.";
      case "auth/invalid-email":
        return "Invalid email. Please try again.";
      case "auth/user-not-found":
        return "User does not exist.";
      case "user-data-not-found":
        return "User data not found. Please try again later.";
      default:
        return "An error occurred. Please try again later.";
    }
  };

  return { signInAndFetchUserData, signOutUser, loading, error, getErrorMessage };
}

export default useSignIn;
