import { db } from "../../config/FirebaseConfig";
import { collection, getDocs, query, orderBy } from "firebase/firestore";

const fetchLeads = async () => {
  try {
    const leadsRef = collection(db, "leads");
    const q = query(leadsRef, orderBy("leadDate", "desc"));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());
    });
    return querySnapshot;

  } catch (error) {
    console.error("Error fetching leads:", error);
    return [];
  }
};

export default fetchLeads;
