import React from 'react';
import { TextField, MenuItem } from '@mui/material';

const CompanySelect = ({ id, label, value, onChange }) => {
  
  const user = JSON.parse(sessionStorage.getItem('userData'));

  const companies = user.company;

  const handleCompanySelect = (event) => {
    const selectedCompanyId = event.target.value;
    const selectedCompany = companies.find(company => company.id === selectedCompanyId);
    onChange(selectedCompany); 
  };

  return (
    <TextField 
      value={value ? value.id : ''} 
      onChange={handleCompanySelect}
      select
      id={id}
      label={label}
      size="small"
      fullWidth
    >
      {companies.map((company) => (
        <MenuItem key={company.id} value={company.id}>
          <div>
            <div>{company.name}</div>
            <div style={{ fontSize: 12, color: 'gray' }}>{company.location}</div>
          </div>
        </MenuItem>
      ))}
    </TextField>
  );
};

export default CompanySelect;
