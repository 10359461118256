import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AppRoutes from './routes/Routes';
import { ThemeProvider } from '@mui/material/styles';
import ardoviaTheme from "./theme/ardoviaTheme";
import './index.css';

function App() {
  return (
    <ThemeProvider theme={ardoviaTheme}>
      <Router>
        <AppRoutes />
      </Router>
    </ThemeProvider>
  );
}

export default App;
