import React from 'react';
import { Box, Divider, Grid, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

function LeadsContent() {
  return (
    <Typography>
      Leads Content
    </Typography>
  );
}

function CalendarContent() {
  return (
    <Typography>
      Calendar Content
    </Typography>
  );
}

function ProposalsContent() {
  return (
    <Typography>
      Proposals Content
    </Typography>
  );
}

function JobsContent() {
  return (
    <Typography>
      Jobs Content
    </Typography>
  );
}

function WarrantyContent() {
  return (
    <Typography>
      Warranty Content
    </Typography>
  );
}

function ReportsContent() {
  return (
    <Typography>
      Reports Content
    </Typography>
  );
}

function SupportContent() {
  return (
    <Typography>
      Support Content
    </Typography>
  );
}

function AlertsContent() {
  return (
    <Typography>
      Alerts Content
    </Typography>
  );
}

function AccountContent() {
  return (
    <Typography>
      Account Content
    </Typography>
  );
}

function NotificationsContent() {
  return (
    <Typography>
      Notifications Content
    </Typography>
  );
}

function SpamContent() {
  return (
    <Typography>
      Spam Content
    </Typography>
  );
}

export default function AccountSettings({user}) {

    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

      const renderContent = () => {
        switch (selectedIndex) {
        case 0:
            return <LeadsContent />;
        case 1:
            return <CalendarContent />;
        case 2:
            return <ProposalsContent />;
        case 3:
            return <JobsContent />;
        case 4:
            return <WarrantyContent />;
        case 5:
            return <ReportsContent />;
        case 6:
            return <SupportContent />;
        case 7:
            return <AlertsContent />;
        case 8:
            return <AccountContent />;
        case 9:
            return <NotificationsContent />;
        case 10:
            return <SpamContent />;
        default:
            return null;
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={2} sx={{pt: "0 !important", borderRight: '1px solid #efefef', fontSize: '10px'}}>
              <List>
                  <ListItemButton
                      selected={selectedIndex === 0}
                      onClick={(event) => handleListItemClick(event, 0)}
                  >
                      <ListItemText primary="Leads" />
                  </ListItemButton>
                  <ListItemButton
                      selected={selectedIndex === 1}
                      onClick={(event) => handleListItemClick(event, 1)}
                  >
                  <ListItemText primary="Calendar" />
                      </ListItemButton>
                  <ListItemButton
                      selected={selectedIndex === 2}
                      onClick={(event) => handleListItemClick(event, 2)}
                  >
                      <ListItemText primary="Proposals" />
                  </ListItemButton>
                  <ListItemButton
                      selected={selectedIndex === 3}
                      onClick={(event) => handleListItemClick(event, 3)}
                  >
                      <ListItemText primary="Jobs" />
                  </ListItemButton>
                  <ListItemButton
                      selected={selectedIndex === 4}
                      onClick={(event) => handleListItemClick(event, 4)}
                  >
                      <ListItemText primary="Warranty" />
                  </ListItemButton>
                  <ListItemButton
                      selected={selectedIndex === 5}
                      onClick={(event) => handleListItemClick(event, 5)}
                  >
                      <ListItemText primary="Reports" />
                  </ListItemButton>
                  <ListItemButton
                      selected={selectedIndex === 6}
                      onClick={(event) => handleListItemClick(event, 6)}
                  >
                      <ListItemText primary="Support" />
                  </ListItemButton>
                  <ListItemButton
                      selected={selectedIndex === 7}
                      onClick={(event) => handleListItemClick(event, 7)}
                  >
                      <ListItemText primary="Alerts" />
                  </ListItemButton>
                  <ListItemButton
                      selected={selectedIndex === 8}
                      onClick={(event) => handleListItemClick(event, 8)}
                  >
                      <ListItemText primary="Account" />
                  </ListItemButton>
              </List>
              <Divider />
              <List>
                  <ListItemButton
                  selected={selectedIndex === 9}
                  onClick={(event) => handleListItemClick(event, 9)}
                  >
                  <ListItemText primary="Notifications" />
                  </ListItemButton>
                  <ListItemButton
                  selected={selectedIndex === 10}
                  onClick={(event) => handleListItemClick(event, 10)}
                  >
                  <ListItemText primary="Spam" />
                  </ListItemButton>
              </List>
            </Grid>
            <Grid item xs={10}>
                {renderContent()}
            </Grid>
        </Grid>
    )
}