import { db } from "../../config/FirebaseConfig";
import { collectionGroup, query, where, getDocs } from "firebase/firestore";

const fetchUsersWithCompanyID = async (companyIds) => {
  try {
    const companyObjects = companyIds.map(id => ({ id }));

    console.log("Company Objects:", companyObjects);

    const q = query(collectionGroup(db, "users"), where("company", "array-contains-any", companyObjects));

    console.log("Query:", q);

    const querySnapshot = await getDocs(q);
    
    const userList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Log the user data
    userList.forEach((user) => {
      console.log(user.id, " => ", user);
    });

    return userList;
  } catch (error) {
    console.error("Error fetching users:", error);
    return [];
  }
};

export default fetchUsersWithCompanyID;
