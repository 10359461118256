import React, { useState, useEffect } from "react";
import MainLayout from "../../layout/MainLayout";
import { Box, Paper, Typography } from "@mui/material";
import Logo from "../../assets/Logo";
import UserLocation from "../../components/Weather/UserLocation";
import fetchUsersWithCompanyID from "../../functions/users/fetchUsersWithCompanyID";

const Test = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const companyIds = ['f5a5ff80-5995-11ee-b405-192db004a613'];

    const getUsers = async () => {
      const fetchedUsers = await fetchUsersWithCompanyID(companyIds);
      setUsers(fetchedUsers);
    };

    getUsers();

  }, []);

  return (
    <MainLayout title="Test">
      <Paper
        variant="outlined"
        sx={{
          py: 1,
          px: 2,
          height: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: "column",
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%'
          }}
        >
          <Logo width={"150px"} />
          <Typography variant="h3" fontWeight={'bold'} sx={{ p: 1, display: 'block', pt: 3 }}>
            Users:
          </Typography>
          {users.length === 0 ? (
            <Typography variant="body1">No users found.</Typography>
          ) : (
            <ul>
              {users.map((user) => (
                <li key={user.id}>
                  <Typography variant="body1">{user.firstName} - {user.email}</Typography>
                  {/* Render other user information as needed */}
                </li>
              ))}
            </ul>
          )}
          <UserLocation />
        </Box>
      </Paper>
    </MainLayout>
  );
};

export default Test;
