import React, { useState } from 'react';
import { TextField } from "@mui/material";

const PhoneNumberInput = ({ id, label, value, required, onChange, onBlur }) => {
  const [phoneNumber, setPhoneNumber] = useState(value || '');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [blurred, setBlurred] = useState(false); // Track if the input has been blurred

  const formatPhone = (phone) => {
    const cleaned = phone.replace(/\D/g, '');
    const formatted = cleaned.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
    return formatted;
  };

  const validatePhone = (phone) => {
    const isValid = /^\d{10}$/.test(phone.replace(/\D/g, ''));
    return isValid;
  };

  const handlePhoneChange = (event) => {
    const formattedPhone = formatPhone(event.target.value);
    setPhoneNumber(formattedPhone);
    if (onBlur) onBlur(formattedPhone); // Pass formatted phone number to onBlur event handler
  };

  const handlePhoneBlur = () => {
    setBlurred(true); // Mark the input as blurred
    const isValid = validatePhone(phoneNumber);
    if (!isValid) {
      setPhoneNumberError('Phone number must be 10 digits');
    } else {
      setPhoneNumberError('');
    }
  };

  return (
    <TextField
      id={id}
      label={label}
      value={phoneNumber}
      required={required}
      onChange={handlePhoneChange}
      onBlur={handlePhoneBlur}
      error={blurred && !!phoneNumberError} // Show error only if input has been blurred
      helperText={blurred ? phoneNumberError : ''}
      size='small'
      fullWidth
    />
  );
};


export default PhoneNumberInput;
