import React, { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction"
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import CalendarSpeedDial from "../../components/Calendar/CalendarSpeedDial"
import moment from 'moment';

const CalendarContainer = () => {
  
  const [currentView, setCurrentView] = useState('dayGridMonth');
  const [isOpen, setIsOpen] = useState(false);
  const [eventDetails, setEventDetails] = useState({});

  const testEvents = [
    {
      title: 'Rhonda Shelton - Design Consultation',
      start: '2024-03-18T16:00:00.000Z',
      end: '2024-03-18T13:00:00.000-0500',
      allDay: false,
      designer: 'Kyle Porter'
    },
    {
      title: 'Jessica Wallace - Design Consultation',
      start: '2024-03-15T16:00:00.000Z',
      end: '2024-03-15T13:00:00.000-0500',
      allDay: false,
    },
    {
      title: 'Althea Howes - Design Consultation',
      start: '2024-03-15T15:00:00.000Z',
      end: '2024-03-15T12:00:00.000-0500',
      allDay: false,
    },
    {
      title: 'Personal Event',
      start: '2024-03-18',
      end: '2024-03-22',
      allDay: true,
    },
    {
      title: 'Nancy Neely - Design Consultation',
      start: '2024-03-14T16:00:00.000Z',
      end: '2024-03-14T13:00:00.000-0500',
      allDay: false,
    },
    {
      title: 'Luke Keheley - Design Consultation',
      start: '2024-03-13T21:00:00.000Z',
      end: '2024-03-13T18:00:00.000-0500',
      allDay: false,
    },
    {
      title: 'Christina Liszewski - Design Consultation',
      start: '2024-03-13T16:00:00.000Z',
      end: '2024-03-13T13:00:00.000-0500',
      allDay: false,
    },
    {
      title: 'Helene Kurtz - Design Consultation',
      start: '2024-03-13T16:00:00.000Z',
      end: '2024-03-13T13:00:00.000-0500',
      allDay: false,
    },
    {
      title: 'Brett Ethridge - Design Consultation',
      start: '2024-03-12T20:00:00.000Z',
      end: '2024-03-12T17:00:00.000-0500',
      allDay: false,
    },
    {
      title: 'Jack Wallace - Follow-Up Consultation',
      start: '2024-03-12T16:00:00.000Z',
      end: '2024-03-12T13:00:00.000-0500',
      allDay: false,
    },
  ];

  const handleViewChange = (newView) => {
    setCurrentView(newView);
  };

  const handleDateClick = (arg) => {
    alert(arg.dateStr);
  };

  const handleEventClick = (clickInfo) => {
    setEventDetails({
      title: clickInfo.event.title,
      start: moment(clickInfo.event.start).format('LLLL'),
      end: moment(clickInfo.event.end).format('LLLL'),
      allDay: clickInfo.event.allDay,
      designer: clickInfo.event.extendedProps.designer
    });
    console.log(eventDetails);
    console.log(clickInfo);
    setIsOpen(true);
  };

  const handleEventClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin]}
        dateClick={handleDateClick}
        eventClick={handleEventClick}
        initialView="dayGridMonth"
        height={'100%'}
        weekends={false}
        events={testEvents}
        editable={true}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
        }}
        views={{
          dayGridMonth: {
            type: 'dayGridMonth',
            buttonText: 'Month',
          },
          timeGridWeek: {
            type: 'timeGridWeek',
            buttonText: 'Week',
          },
          timeGridDay: {
            type: 'timeGridDay',
            buttonText: 'Day',
          },
          listWeek: {
            type: 'listWeek',
            buttonText: 'List',
          },
        }}
        view={currentView} 
        onView={handleViewChange}
        sx={{
          '& #calendar': {
            background: '#ffffff !important',
          }
        }}
      />
      <Dialog open={isOpen} onClose={handleEventClose}>
        <DialogTitle>{eventDetails.title}</DialogTitle>
        <DialogContent>
          <p>Designer: {eventDetails.designer}</p>
          <p>Start: {eventDetails.start}</p>
          <p>End: {eventDetails.end}</p>
          {eventDetails.allDay && <p>All Day</p>}
        </DialogContent>
      </Dialog>
      <CalendarSpeedDial />
    </>
  );
};

export default CalendarContainer;
