import React from "react";

const Logo = ({ width }) => {
  return (
    <img
      src="/assets/logo.png"
      alt="Omevi Logo"
      width="100%"
      style={{ width: width, height: "auto" }}
    />
  );
};

export default Logo;
