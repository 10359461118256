import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import HighlightOff from '@mui/icons-material/HighlightOff';
import { useTheme } from '@mui/material/styles';

export default function CompanyMultiSelect({ label }) {
    const theme = useTheme();
    const user = JSON.parse(sessionStorage.getItem('userData'));
    const companies = user.company;
    const [selected, setSelected] = React.useState([]);

    const handleChange = (event) => {
        setSelected(event.target.value);
    };

  const handleDelete = (value) => {
    setSelected(selected.filter((item) => item !== value));
  };

  return (
    <div>
      <FormControl sx={{ minWidth: 200 }} size="small">
        <InputLabel id="select-companies-label">{label}</InputLabel>
        <Select
          labelId="select-companies-label"
          id="select-companies"
          multiple
          value={selected}
          onChange={handleChange}
          input={<OutlinedInput id="select-companies" label={label} sx={{ height: theme.spacing('36.5px'), alignItems: 'center' }} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  onDelete={() => handleDelete(value)}
                  sx={{ whiteSpace: 'nowrap', height: '24px !important', lineHeight: '24px'}}
                  onMouseDown={(event) => event.stopPropagation()}
                />
              ))}
            </Box>
          )}
        >
          {companies.map((company) => (
            <MenuItem key={company.id} value={company.name}>
              <div>
                <div>{company.name}</div>
                <div style={{ fontSize: '0.8em', color: 'gray' }}>{company.location}</div>
              </div>
              {selected.includes(company.name) && (
                <ListItemIcon sx={{ justifyContent: 'flex-end', marginLeft: 'auto' }}>
                  <IconButton
                    size="small"
                    edge="end"
                    onClick={() => handleDelete(company.name)}
                  >
                    <HighlightOff />
                  </IconButton>
                </ListItemIcon>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
