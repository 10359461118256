import { db } from "../../config/FirebaseConfig";
import { collection, addDoc } from "firebase/firestore";

const createLead = async (leadData, completionHandler) => {
  try {
    const docRef = await addDoc(collection(db, "leads"), leadData);
    console.log('Lead document created successfully with ID:', docRef.id);
    completionHandler(null); 
  } catch (error) {
    console.error('Error creating lead document:', error);
    completionHandler(error); 
  }
};

export default createLead;
