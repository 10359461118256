import React from 'react';
import { TextField, MenuItem } from '@mui/material';

const SourceSelect = ({ id, label, value, onChange }) => {
  const sources = [
    { value: 'Bing', label: 'Bing Ads' },
    { value: 'Eblast', label: 'Eblast' },
    { value: 'Facebook', label: 'Facebook Ads' },
    { value: 'Google', label: 'Google Ads' },
    { value: 'Local Services', label: 'Local Services' },
    { value: 'Organic', label: 'Organic' },
    { value: 'Phone', label: 'Phone' },
    { value: 'Print', label: 'Print' },
    { value: 'Referral', label: 'Referral' },
    { value: 'Retargeting', label: 'Retargeting' },
    { value: 'Social', label: 'Social Media' },
    { value: 'Trade Show', label: 'Trade Show' }
  ];

  return (
    <TextField 
      value={value}
      onChange={onChange}
      select
      id={id}
      label={label}
      size="small"
      fullWidth
    >
      {sources.map((source) => (
        <MenuItem key={source.value} value={source.value}>
          {source.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SourceSelect;
