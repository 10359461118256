import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBcB0Qr7WTWv1aZ1JNRAp27H2roi0o_s04",
  authDomain: "omevi-app.firebaseapp.com",
  databaseURL: "https://omevi-app-default-rtdb.firebaseio.com",
  projectId: "omevi-app",
  storageBucket: "omevi-app.appspot.com",
  messagingSenderId: "364432290580",
  appId: "1:364432290580:web:04a89e997ec10a158a8801",
  measurementId: "G-P4PBQZZJ0W"
};


const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export const analytics = getAnalytics(firebaseApp);
export default firebaseConfig;
