import React from "react";
import { Box, CircularProgress, Tooltip, Typography } from "@mui/material";
import { useCountUp } from 'use-count-up';

export default function FilledCircularPercentage({ count, percentage, name }) {
  
  const tooltipText = "Total Sits: " + count ;
  const { value, reset } = useCountUp({
        isCounting: true,
        end: percentage, 
        duration: 0.5, 
    });

    return (
        <Tooltip title={tooltipText} placement="top">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box sx={{ position: 'relative', display: 'inline-flex', flexDirection: 'column', alignItems: 'center', lineHeight: '0' }}>
                    <Box sx={{ position: 'relative' }}>
                        <CircularProgress
                        variant="determinate"
                        sx={{
                            color: (theme) => theme.palette.grey[200],
                            position: 'absolute',
                            top: 0,
                            left: 0,
                        }}
                        size={80}
                        thickness={4}
                        value={100}
                        />
                        <CircularProgress 
                        variant="determinate" 
                        value={value}
                        color="primary"
                        size={80}
                        thickness={4}
                        sx={{
                            color: 'primary',
                            '& .MuiCircularProgress-circle': {
                            strokeLinecap: 'round'
                            },
                        }}
                        />
                    </Box>
                    <Box
                        sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        }}
                    >
                        <Typography sx={{fontSize: '18px', color: (theme) => theme.palette.ardovia.dark }}>
                        {percentage}%
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{mt: 1}}>
                    <Typography align="center" sx={{fontWeight: 'bold'}}>
                    {name}
                    </Typography>
                </Box>
            </Box>
        </Tooltip>
    );
};
