import * as React from "react";
import { Avatar, Box, Button, Chip, Grid, IconButton } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import moment from "moment";
import LoadingButton from '@mui/lab/LoadingButton';
import fetchLeads from "../../functions/leads/fetchLeads";
import MainLayout from "../../layout/MainLayout";
import CreateLeadModal from "../../components/Leads/CreateLeadModal";
import SearchInput from "../../components/Inputs/SearchInput";
import CompanyMultiSelect from "../../components/Inputs/CompanyMultiSelect"
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { Edit, CalendarMonth, Delete, Paid, Visibility } from "@mui/icons-material";



export default function Leads() {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState('');

  useEffect(() => {
    fetchLeadsData();
  }, []);

  const fetchLeadsData = async () => {
    try {
      const querySnapshot = await fetchLeads();
      const leadsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLeads(leadsData);
      setLoading(false); // Data has been loaded, set loading to false
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };


  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };

  const viewEdit = (row) => {
    console.log(`ID: ${row.id}, Full Name: ${row.fullName}`);
  }

  // Define functions
  const deleteUser = (id) => () => {
    console.log("Delete User");
  };

  const toggleAdmin = (id) => () => {
    console.log("Toggle Admin");
  };

  const duplicateUser = (id) => () => {
    console.log("Duplicate User");
  };

  const handleCreateLead = () => {
    setOpenModal(true); // Open the modal when "Create Lead" button is clicked
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      headerAlign: 'center',
      width: 30,
      align: "center",
      renderCell: (params) => (
        <Tooltip title={`ID: ${params.value}`} placement="top" arrow>
          <InfoIcon />
        </Tooltip>
      ),
    },
    {
      field: "leadDate",
      headerName: "Date",
      width: 100,
      renderCell: (params) => (
        <Tooltip title={moment(params.value).format('llll')} placement="top" arrow>
          {moment(params.value).format('L')}
        </Tooltip>
      ),
    },
    {
      field: "fullName",
      headerName: "Name",
      minWidth: 100,
      flex: 1,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 100,
      flex: 1,
      editable: false,
    },
    {
      field: "phone",
      headerName: "Phone",
      minWidth: 100,
      flex: 1,
      editable: false,
    },
    {
      field: "city",
      headerName: "Location",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      minWidth: 100,
      flex: 1,
      valueGetter: (params) =>
        `${!!params.row.city ? params.row.city + ", " : ""}${!!params.row.state ? params.row.state + " " : ""} ${!!params.row.zip ? params.row.zip : ""}`,
    },
    {
      field: "source",
      headerName: "Source",
      headerAlign: 'center',
      width: 120,
      align: 'center',
      renderCell: (params) => (
        <Chip label={params.value} variant="outlined" width='100%' size="small" sx={{width: '100%'}} />
      ),
    },
    {
      field: "company",
      headerName: "Company",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const company = params.value;
        if (!company || !company.name) {
          return null; // Return null if company data is missing
        }
        const { id, name, location } = company;
        const initials = name.split(' ').map(part => part[0]).join('');
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Avatar size='small' sx={{fontSize: '13px', fontWeight: 'bold',  width: 30, height: 30 }} src="https://firebasestorage.googleapis.com/v0/b/krr-enterprises.appspot.com/o/companies%2FSP%20Icon%20-%20White%20Background.jpg?alt=media&token=4e0dccea-b6fa-4cb9-b4e9-2c389cf2a0aa" />
            <div>
              <div>{name}</div>
              <div style={{ fontSize: '12px', color: 'gray' }}>{location}</div>
            </div>
          </div>
        );
      }
    },
    // {
    //   field: "buttonColumn1",
    //   headerName: "",
    //   width: 130,
    //   renderCell: (params) => (
    //     <Button variant="outlined" color="primary" size="small" sx={{width: '100%'}} onClick={() => viewEdit(params.row)}>
    //       View / Edit
    //     </Button>
    //   ),
    // },
    // // Column for Button 2
    // {
    //   field: "buttonColumn2",
    //   headerName: "",
    //   width: 165,
    //   renderCell: (params) => (
    //     <Button variant="outlined" color="primary" size="small" sx={{width: '100%'}}>
    //       Schedule Appt.
    //     </Button>
    //   ),
    // },
    // {
    //   field: "buttonColumn3",
    //   headerName: "",
    //   width: 175,
    //   renderCell: (params) => (
    //     <Button variant="outlined" color="primary" size="small" sx={{width: '100%'}}>
    //       Create Proposal
    //     </Button>
    //   ),
    // },
    {
      field: "view",
      headerName: "",
      width: 30,
      align: "center",
      renderCell: (params) => (
        <Tooltip title="View" placement="top" arrow>
          <IconButton>
            <Visibility />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: "edit",
      headerName: "",
      width: 30,
      align: "center",
      renderCell: (params) => (
        <Tooltip title="Edit" placement="top" arrow>
          <IconButton>
            <Edit />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: "schedule",
      headerName: "",
      width: 30,
      align: "center",
      renderCell: (params) => (
        <Tooltip title="Schedule Appt." placement="top" arrow>
          <IconButton>
            <CalendarMonth />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: "proposal",
      headerName: "",
      width: 30,
      align: "center",
      renderCell: (params) => (
        <Tooltip title="Create Proposal" placement="top" arrow>
          <IconButton>
            <Paid />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: "delete",
      headerName: "",
      width: 30,
      align: "center",
      renderCell: (params) => (
        <Tooltip title="Delete" placement="top" arrow>
          <IconButton>
            <Delete />
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  return (
    <MainLayout title="Leads">
      <Box sx={{ height: "100%", width: "100%"}}>
        <Grid container spacing={2} sx={{ marginBottom: 2, justifyContent: 'flex-end' }}>
          <Grid item s={2}>
            <SearchInput />
          </Grid>
          <Grid item s={2}>
            <CompanyMultiSelect
              label={'Filter by Company'}
              />
          </Grid>
          {/* <Grid item s={2}>
            <Button variant="contained" color="primary">
              Button 3
            </Button>
          </Grid>
          <Grid item s={2}>
            <Button variant="contained" color="primary">
              Button 4
            </Button>
          </Grid> */}
          <Grid item s={2}>
             <LoadingButton
                onClick={fetchLeadsData}
                loading={loading}
                loadingIndicator="Loading…"
                variant="outlined"
              >
              <span>Fetch data</span>
            </LoadingButton>
          </Grid>
          <Grid item s={2}>
            <Button variant="outlined" color="primary" onClick={handleCreateLead} >
              Create Lead
            </Button>
          </Grid>
        </Grid>
        <Box sx={{backgroundColor: '#ffffff', height: 'calc(100% - 56px)'}}>
          <DataGrid
            rows={leads}
            columns={columns}
            pageSize={5}
            disableSelectionOnClick
            disableColumnMenu
            disableColumnFilter 
            loading={loading}
            sx={{
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
            }}
          />
        </Box>
        <CreateLeadModal open={openModal} onClose={handleCloseModal} onSuccess={fetchLeadsData} />
      </Box>
    </MainLayout>
  );
}
