import React, { useState, useEffect } from 'react';
import { Grid, Box, Divider, TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, CircularProgress } from "@mui/material";
import CompanySelect from '../Inputs/CompanySelect';
import StateSelect from "../Inputs/StateSelect";
import SourceSelect from '../Inputs/SourceSelect';
import PhoneNumberInput from '../Inputs/PhoneNumberInput';
import EmailInput from '../Inputs/EmailInput';
import createLead from '../../functions/leads/createLead';
import moment from 'moment';


export default function CreateLeadModal({ open, onClose, onSuccess }) {
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedSource, setSelectedSource] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  

  // Reset the form when the modal is dismissed
  useEffect(() => {
    if (!open) {
      setSelectedCompany('');
      setSelectedState('');
      setSelectedSource('');
      setPhone('');
      setEmail('');
      setPhoneError('');
      setEmailError('');
    }
  }, [open]);

  const handleCompanyChange = (event) => {
    setSelectedCompany(event);
  };
  
  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  const handleSourceChange = (event) => {
    setSelectedSource(event.target.value);
  };

  const handlePhoneChange = (formattedPhone) => {
    setPhone(formattedPhone);
  };

  const handleEmailChange = (formattedEmail) => {
    setEmail(formattedEmail);
  };

  const handlePhoneBlur = (formattedPhone) => {
    const isValid = /^\d{10}$/.test(formattedPhone.replace(/\D/g, ''));
    if (!isValid) {
      setPhoneError('Phone number must be 10 digits');
    } else {
      setPhoneError('');
    }
  };

  const handleEmailBlur = (formattedEmail) => {
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formattedEmail);
    if (!isValid) {
      setEmailError('Invalid email format');
    } else {
      setEmailError('');
    }
  };

  const areRequiredFieldsFilled = () => {
    const requiredFields = ['firstName', 'lastName', 'email', 'phone']; // Add other required field IDs as needed
    let isEmpty = false;
    requiredFields.forEach(field => {
      const element = document.getElementById(field);
      if (!element?.value.trim()) {
        isEmpty = true;
        console.log(`${field} is empty`);
      }
    });

    // Check if any custom inputs have errors
    if (phoneError || emailError || !selectedCompany) {
      return false;
    }

    return !isEmpty;
  };


  const handleSubmit = () => {
    setLoading(true);
    
    if (areRequiredFieldsFilled()) {
      const firstName = document.getElementById('firstName').value;
      const lastName = document.getElementById('lastName').value;
      const leadData = {
        firstName: firstName,
        lastName: lastName,
        fullName: `${firstName} ${lastName}`,
        email: document.getElementById('email').value,
        phone: document.getElementById('phone').value,
        address: document.getElementById('address').value,
        city: document.getElementById('city').value,
        state: selectedState,
        zip: document.getElementById('zip').value,
        source: selectedSource,
        company: {
          id: selectedCompany.id,
          name: selectedCompany.name,
          location: selectedCompany.location
        },
        leadDate: moment().format(),
        status: 'New',
      };
      createLead(leadData, (error) => {
        if (error) {
          setLoading(false);
        } else {
          console.log("Lead document created successfully");
          onClose();
          onSuccess();
          setLoading(false);
        }
      });
    }
  };

  const handleCloseSnackbar = () => {
    setErrorMessage('');
  };
 

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{backgroundColor: "#efefef", fontWeight: "bold"}}>Create New Lead</DialogTitle>
      <Divider />
      <DialogContent>
        <Box
          component="form"
          sx={{
            width: "100%",
            marginTop: 1,
          }}
          noValidate
          autoComplete="off"
        >
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item xs={6}>
              <TextField
                id="firstName"
                label="First Name"
                variant="outlined"
                size="small"
                required={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="lastName"
                label="Last Name"
                variant="outlined"
                size="small"
                required={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <EmailInput
                id="email"
                label="Email"
                required={true}
              />
            </Grid>
            <Grid item xs={6}>
              <PhoneNumberInput
                id="phone"
                label="Phone"
                value={phone}
                required={true}
                onChange={handlePhoneChange}
                onBlur={handlePhoneBlur}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="address"
                label="Address"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="city"
                label="City"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <StateSelect
                id="state"
                label="State"
                value={selectedState}
                onChange={handleStateChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="zip"
                label="Zip"
                variant="outlined"
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <SourceSelect
                id="source"
                label="Source"
                value={selectedSource}
                onChange={handleSourceChange}
              />
            </Grid>
            <Grid item xs={6}>
              <CompanySelect
                id="company"
                label="Company"
                value={selectedCompany}
                onChange={handleCompanyChange}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions sx={{px: 3, py: 2}}>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained" 
        disabled={loading || !areRequiredFieldsFilled()}>
          {loading ? <CircularProgress size={24} /> : 'Create'}
        </Button>
      </DialogActions>
      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={errorMessage}
      />
    </Dialog>
  );
}
