import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MainLayout from "../../layout/MainLayout";
import Chart from "../Dashboard/Chart";

export default function Reports() {
  return (
    <MainLayout title={"Reports"}>
      {/* <Box sx={{ height: '100%', width: '100%', backgroundColor: '#ffffff' }}> */}
      <Paper
        variant="outlined"
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: 240,
        }}
      >
        <Chart />
      </Paper>
      {/* </Box> */}
    </MainLayout>
  );
}
