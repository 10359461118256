import React, { useState, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { auth } from "../config/FirebaseConfig";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

const PrivateRoute = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false); // Set loading to false once authentication status is determined
    });
    return () => unsubscribe();
  }, []);

  // Render loading state while authentication status is being determined
  if (loading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  // If on the sign-in page and already logged in, redirect to dashboard
  if (location.pathname === "/signin" && user) {
    return <Navigate to="/dashboard" />;
  }

  // If on the homepage and logged in, redirect to dashboard
  if (location.pathname === "/" && user) {
    return <Navigate to="/dashboard" />;
  }

  // Redirect to sign-in page if user is not authenticated
  return user ? <Outlet /> : <Navigate to="/signin" />;
};

export default PrivateRoute;
