import React from "react";
import { Avatar, Box, Divider, IconButton, List, Drawer as MuiDrawer, Toolbar, Typography } from "@mui/material";
import { ChevronLeft as ChevronLeftIcon } from "@mui/icons-material";
import Logo from "../assets/Logo";
import MenuItems from "../components/Menu/MenuItems";
import { styled, theme } from "@mui/material/styles";

const DesktopDrawer = ({ drawerOpen, toggleDrawer, menuItems, activeMenuItem, handleNavClick, user, drawerWidth }) => {
    
    const DesktopDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "drawerOpen"})(
        ({ theme, drawerOpen }) => ({
        "& .MuiDrawer-paper": {
            position: "relative",
            whiteSpace: "nowrap",
            width: drawerOpen ? drawerWidth : theme.spacing(7),
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: drawerOpen ? theme.transitions.duration.enteringScreen: theme.transitions.duration.leavingScreen,
            }),
            boxSizing: "border-box",
            overflowX: drawerOpen ? "hidden" : "visible",
        },
    }));

    return (
        <DesktopDrawer variant="permanent" drawerOpen={drawerOpen}>
            <Toolbar
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    px: [1],
                }}
            >
                <Box
                    sx={{
                        width: "150px",
                        p: 1,
                        bgcolor: "background.paper",
                        display: "flex",
                        alignItems: "left",
                    }}
                >
                    <Logo width={"100%"} />
                </Box>
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
            </Toolbar>
            <Divider />
            <Box sx={{ overflowY: "auto", flexGrow: 1 }}>
                <List>
                    <MenuItems menuItems={menuItems} activeMenuItem={activeMenuItem} />
                </List>
            </Box>
            <Box
                sx={{
                    width: drawerWidth,
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    bgcolor: "background.paper",
                    borderRight: "1px solid #0000001f",
                }}
            >
                <Divider />
                <Box
                    sx={{
                        m: 1,
                        px: 2,
                        py: 1,
                        borderRadius: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        "&:hover": {
                            bgcolor: "action.hover",
                        },
                    }}
                    onClick={() => handleNavClick("/account")}
                >
                    <Avatar alt={user?.fullName} src={user?.profilePhoto} />
                    <Box>
                        <Typography sx={{ ml: 1, display: "block" }}>{user?.fullName}</Typography>
                        <Typography sx={{ ml: 1, fontSize: 12, color: "#8c8c8c", display: "block", fontStyle: "italic" }}>{user?.jobTitle}</Typography>
                    </Box>
                </Box>
            </Box>
        </DesktopDrawer>
    );
};

export default DesktopDrawer;
