import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";

const MenuItems = ({ menuItems, activeMenuItem }) => {
  const navigate = useNavigate();

  const handleClick = (path) => {
    navigate(path);
  };

  return (
    <React.Fragment>
      {menuItems.map((item) => (
        <ListItemButton
          key={item.path}
          selected={activeMenuItem === item.name}
          onClick={() => handleClick(item.path)}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.name} />
        </ListItemButton>
      ))}
    </React.Fragment>
  );
};

export default MenuItems;
