import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Badge, Box, Button, Menu, MenuItem, Avatar, Divider, ListItemIcon } from '@mui/material';
import { Menu as MenuIcon, Notifications as NotificationsIcon, AccountCircle as AccountCircleIcon, Settings as SettingsIcon, LogoutOutlined as LogoutIcon } from '@mui/icons-material';
import { styled } from "@mui/material/styles";
import { hasPermission } from '../utility/permissions';

const DesktopAppBar = ({ title, drawerOpen, onToggleDrawer, user, handleProfileClick, handleProfileClose, handleSignOut, profileMenuOpen, handlelNavClick, drawerWidth }) => {

    const DesktopAppBar = styled(AppBar, { shouldForwardProp: (prop) => prop !== "drawerOpen" })(
        ({ theme, drawerOpen }) => ({
        zIndex: theme.zIndex.drawer + 1,
        marginLeft: drawerOpen ? drawerWidth : 0,
        width: `calc(100% - ${drawerOpen ? drawerWidth : 0}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }));
    
    const adminView = hasPermission(user, 'admin', 'view');

    return (
        <DesktopAppBar position="absolute" drawerOpen={drawerOpen} sx={{backgroundColor: 'ardovia.main'}}>
        <Toolbar>
            <IconButton
            edge="start"
            color="inherit"
            aria-label="drawerOpen drawer"
            onClick={onToggleDrawer}
            sx={{
                mr: 5,
                ...(drawerOpen && { display: "none" }),
            }}
            >
            <MenuIcon />
            </IconButton>
            <Typography
            component="h1"
            variant="h6"
            color="inherit"
            fontWeight="bold"
            noWrap
            sx={{ flexGrow: 1 }}
            >
            {title}
            </Typography>
            <IconButton color="inherit" 
                sx={{
                    mr: 3,
                    '&:hover': {
                    bgcolor: 'ardovia.light',
                    }
                }}
            >
            <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
            </Badge>
            </IconButton>
            {adminView && (
            <Button 
            variant="outlined" 
            size='small' 
            sx={{
                mr: 3, 
                borderColor: '#bdbdbd', 
                color: '#bdbdbd'
            }} 
            onClick={() => handlelNavClick("/admin")}
            >
            Admin Console
            </Button>
            )}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: 'pointer',
                    p: 1,
                    borderRadius: 1,
                    '&:hover': {
                    bgcolor: 'ardovia.light',
                    },
                }}
                onClick={handleProfileClick}
            >
                <Avatar 
                    alt={user?.fullName}
                    src={user?.profilePhoto}
                    sx={{ width: 32, height: 32 }} 
                />
                <Typography sx={{ ml: 1 }}>
                    {user?.fullName}
                </Typography>
            </Box>
            <Menu
                id="account-menu"
                open={profileMenuOpen}
                onClose={handleProfileClose}
                slotProps={{
                    paper: {
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        width: '200px',
                        mt: 5,
                        '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                        },
                        '&::before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 40,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                        },
                    },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
            <MenuItem onClick={() => handlelNavClick("/account")}>
                <Avatar /> Account
            </MenuItem>
            <Divider />
            <MenuItem onClick={() => handlelNavClick("/account")}>
                <ListItemIcon>
                <SettingsIcon fontSize="small" />
                </ListItemIcon>
                Settings
            </MenuItem>
            <MenuItem onClick={(handleSignOut)}>
                <ListItemIcon>
                <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Logout
            </MenuItem>
            </Menu>
        </Toolbar>
        </DesktopAppBar>
    );
};

export default DesktopAppBar;
