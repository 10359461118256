import React from "react";
import MainLayout from "../../layout/MainLayout";
import { Avatar, Box, Button, Card, CardContent, Divider, Grid, Tab, Typography } from "@mui/material";
import { SettingsOutlined, SettingsInputComponentOutlined, LockOutlined, ExitToApp } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AccountSettings from "../../components/Account/AccountSettings";
import useSignIn from "../../hooks/auth/useSignIn"

const Account = () => {
  
  const user = JSON.parse(sessionStorage.getItem('userData'));
  const { signOutUser } = useSignIn();
  
  const [value, setValue] = React.useState('settings');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MainLayout title="Account">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Card variant="outlined">
            <CardContent sx={{textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', p: 3, mt: 2 }}>
              <Avatar 
                alt={user?.fullName}
                src={user?.profilePhoto}
                color="primary"
                sx={{ width: '75px', height: '75px', textAlign: 'center', }} 
              />
              <Typography variant="h4" sx={{mt: 3}}>
                {user.fullName}
              </Typography>
              <Typography sx={{color: (theme) => theme.palette.grey[400], fontStyle: 'italic' }}>
                {user.jobTitle}
              </Typography>
              <Divider sx={{ width: '100%', my: 2 }} />
              <Typography>
                {user.phone}
              </Typography>
              <Button variant="outlined" startIcon={<ExitToApp />} sx={{mt:2}} onClick={signOutUser} >
                Sign Out
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Box sx={{ width: '100%'}}>
            <Card variant="outlined">
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange}>
                  <Tab value="settings" label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <SettingsOutlined sx={{ fontSize: 18, mr: 1 }} /> Settings
                    </div>
                  } sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }} />
                  <Tab value="2" label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <SettingsInputComponentOutlined sx={{ fontSize: 18, mr: 1 }} /> Integrations
                    </div>
                  } sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }} />
                  <Tab value="3" label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <LockOutlined sx={{ fontSize: 18, mr: 1 }} /> Change Password
                    </div>
                  } sx={{ textTransform: 'none', '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }} />
                </TabList>
              </Box>
              <TabPanel value="settings" sx={{pl: 0, pb: 0, pt: 2}}><AccountSettings /></TabPanel>
              <TabPanel value="2">Item Two</TabPanel>
              <TabPanel value="3">Item Three</TabPanel>
            </TabContext>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default Account;
