import React, { useState } from "react";
import { Alert, Box, Button, Checkbox, Container, CssBaseline, FormControlLabel, Grid, Link, TextField, CircularProgress, Typography } from "@mui/material";
import Logo from "../../assets/Logo";
import useSignIn from "../../hooks/auth/useSignIn";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signInAndFetchUserData, loading, error, getErrorMessage } = useSignIn();

  const handleSignIn = async (e) => {
    e.preventDefault();
    signInAndFetchUserData(email, password);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 12,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Logo width={'250px'} />
        <Typography sx={{textAlign: 'center', mt: 2, fontSize: '12.5px'}}>Sign in to your client dashboard using your provded credentials below.</Typography>
        {error && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {getErrorMessage(error)}
          </Alert>
        )}
        <Box component="form" onSubmit={handleSignIn} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={loading}
            sx={{ mt: 3, mb: 2 }}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Sign In"}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}

export default SignIn;
