import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, CssBaseline, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DesktopAppBar from "./DesktopAppBar";
import DesktopDrawer from "./DesktopDrawer";
import useSignIn from "../hooks/auth/useSignIn";
import MenuGenerator from "./MenuGenerator";

export const MainLayout = ({ children, title }) => {
  const user = JSON.parse(sessionStorage.getItem('userData'));
  const adminView = true;
  const { signOutUser } = useSignIn();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const desktopDrawerWidth = 240;

  const handleProfileClick = (event) => {
    setProfileMenuOpen(event.currentTarget);
  };

  const handleProfileClose = () => {
    setProfileMenuOpen(false);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handlelNavClick = (path) => {
    navigate(path);
  };

  const handleSignOut = async () => {
    await signOutUser(); 
  };

  // Generate menu items based on user permissions
  const menuItems = MenuGenerator({ user });

  // update active menu item
  useEffect(() => {
    const menuItem = menuItems.find((item) => item.path === currentPath);
    if (menuItem) {
      setActiveMenuItem(menuItem.name);
    } else {
      setActiveMenuItem("");
    }
  }, [currentPath, menuItems]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {isMobile ? (
        <Box
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "calc(100vh - 64px)",
              overflow: "auto",
              marginTop: "64px",
              p: 2,
              transition: "padding-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
            }}
          >
          {children}
        </Box>
      ) : (
        <>
          <DesktopAppBar
            title={title}
            drawerOpen={drawerOpen}
            onToggleDrawer={toggleDrawer}
            user={user}
            handleProfileClick={handleProfileClick}
            handleProfileClose={handleProfileClose}
            profileMenuOpen={profileMenuOpen}
            handlelNavClick={handlelNavClick}
            handleSignOut={handleSignOut}
            drawerWidth={desktopDrawerWidth}
          />
          <DesktopDrawer
            drawerOpen={drawerOpen}
            toggleDrawer={toggleDrawer}
            menuItems={menuItems}
            activeMenuItem={activeMenuItem}
            handlelNavClick={handlelNavClick}
            user={user}
            drawerWidth={desktopDrawerWidth}
          />
          <Box
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "calc(100vh - 64px)",
              overflow: "auto",
              marginTop: "64px",
              p: 2,
              transition: "padding-left 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
            }}
          >
            {children}
          </Box>
        </>
      )}
    </Box>
  );
};

export default MainLayout;
