
export function hasPermission(user, feature, action) {
  // Check if the user object and permissions are valid
  if (!user || !user.permissions) {
    return false;
  }

  // Check if the feature and action exist in the user's permissions
  if (
    user.permissions[feature] &&
    user.permissions[feature][action] !== undefined
  ) {
    return user.permissions[feature][action];
  }

  return false;
}
