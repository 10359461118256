import React from "react";
import MainLayout from "../../layout/MainLayout";
import { Box, Grid, Card, CardContent, Typography } from "@mui/material";
import { Facebook, Google, Phone, Email, LocationOn, Park } from "@mui/icons-material";
import FilledCircularPercentage from "../../components/Percentage/FilledCircularPercentage";
import Chart from "./Chart";
import BarChartTest  from "../../components/Graphs/BarChartTest"


export default function Dashboard() {

  const user = JSON.parse(sessionStorage.getItem('userData'));

  return (
    <MainLayout title={"Dashboard"}>
      {/* <Typography variant="h4" sx={{fontWeight:'bold'}}>Leads</Typography> */}
      <Grid container spacing={2}>
        <Grid item xs={12} >
          <Card variant="outlined">
            <CardContent>
              <BarChartTest />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} >
          <Card variant="outlined">
            <CardContent>
              <Park sx={{color: 'success.main'}}/>
              <Typography variant="h6">
                Orgnic Leads
              </Typography>
              <Typography variant="h2">
                456
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} >
          <Card variant="outlined">
            <CardContent>
              <Facebook sx={{color: 'primary.main'}}/>
              <Typography variant="h6">
                Facebook Leads
              </Typography>
              <Typography variant="h2">
                456
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} >
          <Card variant="outlined">
            <CardContent>
              <Google sx={{color: 'error.main'}}/>
              <Typography variant="h6">
                Google Leads
              </Typography>
              <Typography variant="h2">
                456
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} >
          <Card variant="outlined">
            <CardContent>
              <Phone sx={{color: 'warning.main'}} />
              <Typography variant="h6">
                Phone Leads
              </Typography>
              <Typography variant="h2">
                456
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} >
          <Card variant="outlined">
            <CardContent>
              <Email />
              <Typography variant="h6">
                Eblast Leads
              </Typography>
              <Typography variant="h2">
                456
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} >
          <Card variant="outlined">
            <CardContent>
              <LocationOn />
              <Typography variant="h6">
                Local Listing Leads
              </Typography>
              <Typography variant="h2">
                456
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={6} >
          <Card variant="outlined">
            <CardContent>
              <Email />
              <Typography variant="h6">
                Eblast Leads
              </Typography>
              <Grid container>
                <Grid item xs={2}>
                  <FilledCircularPercentage count={5} percentage={75} name={'Facebook'} />
                </Grid>
                <Grid item xs={2}>
                  <FilledCircularPercentage percentage={55} name={'Google Ads'} />
                </Grid>
                <Grid item xs={2}>
                  <FilledCircularPercentage percentage={15} name={'Phone'} />
                </Grid>
                <Grid item xs={2}>
                  <FilledCircularPercentage percentage={15} name={'Phone'} />
                </Grid>
                <Grid item xs={2}>
                  <FilledCircularPercentage percentage={15} name={'Phone'} />
                </Grid>
                <Grid item xs={2}>
                  <FilledCircularPercentage percentage={15} name={'Phone'} />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} >
          <Card variant="outlined">
            <CardContent>
              <BarChartTest />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </MainLayout>
  );
}
