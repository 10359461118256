import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import SignIn from '../pages/SignIn/SignIn';
import Dashboard from '../pages/Dashboard/Dashboard';
import Leads from '../pages/Leads/Leads';
import Calendar from '../pages/Calendar/Calendar';
import Test from '../pages/Static/Test';
import Reports from '../pages/Reports/Reports';
import Proposals from '../pages/Proposals/Proposals';
import Jobs from '../pages/Jobs/Jobs';
import Warranty from '../pages/Warranty/Warranty'
import Support from '../pages/Support/Support';
import Alerts from '../pages/Alerts/Alerts';
import Account from '../pages/Account/Account';
import Admin from '../pages/Admin/Admin';
import { hasPermission } from '../utility/permissions';

const AppRoutes = () => {
    const user = JSON.parse(sessionStorage.getItem('userData'));
    const leadsView = hasPermission(user, 'leads', 'view');
    const calendarView = hasPermission(user, 'calendar', 'view');
    const proposalsView = hasPermission(user, 'proposals', 'view');
    const jobsView = hasPermission(user, 'jobs', 'view');
    const warrantyView = hasPermission(user, 'warranty', 'view');
    const reportsView = hasPermission(user, 'reports', 'view');
    const adminView = hasPermission(user, 'admin', 'view');
  
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/dashboard" element={<Dashboard />} />
        {leadsView && <Route path="/leads" element={<Leads />} />}
        {calendarView && <Route path="/calendar" element={<Calendar />} />}
        {proposalsView && <Route path="/proposals" element={<Proposals />} />}
        {jobsView && <Route path="/jobs" element={<Jobs />} />}
        {warrantyView && <Route path="/warranty" element={<Warranty />} />}
        {reportsView && <Route path="/reports" element={<Reports />} />}
        <Route path="/support" element={<Support />} />
        <Route path="/test" element={<Test />} />
        <Route path="/alerts" element={<Alerts />} />
        <Route path="/account" element={<Account />} />
        {adminView && <Route path="/admin" element={<Admin />} />}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
      <Route path="/signin" element={<SignIn />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default AppRoutes;
