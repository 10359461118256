import React from "react";
import { Box } from "@mui/material";
import MainLayout from "../../layout/MainLayout";
import CalendarContainer from "../../components/Calendar/CalendarContainer";

const Calendar = () => {
  return (
    <MainLayout title="Calendar">
      <Box sx={{ height: "100%", width: "100%" }}>
        <CalendarContainer />
      </Box>
    </MainLayout>
  );
};

export default Calendar;
