import { createTheme } from '@mui/material/styles';

const ardoviaTheme = createTheme({
  palette: {
    primary: {
      main: '#317FA5',
    //   light: '#4791db',
    //   dark: '#115293',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F58F5B',
    //   light: '#e33371',
    //   dark: '#9a0036',
      contrastText: '#fff',
    },
    tertiary: {
        main: '#475C6F'
    },
    error: {
      main: '#f44336',
    //   light: '#e57373',
    //   dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: '#262626',
      secondary: '#333',
      disabled: '#666',
    },
    background: {
      default: '#fafafa',
      paper: '#ffffff'
    },
    action: {
      active: '#475C6F',
    //   hover: '#7b1fa2',
    //   selected: '#e1bee7',
    //   disabled: '#9e9e9e',
    },
    ardovia: {
        main: '#1E2C38',
        light: '#324251'
    },
    leads: {
        organic: '',
        facebook: '',
        google: '',
        phone: '',
        eblast: '',
        social: '',
        referral: '',
        bing: '',
    },
    success: {
      main: '#128747', 
    //   light: green[200], 
    //   dark: green[900], 
      contrastText: '#fff', 
    },
    info: {
      main: '#3C92DD', 
    //   light: blue[200], 
    //   dark: blue[900], 
      contrastText: '#fff',
    },
    warning: {
      main: '#D17823', 
    //   light: orange[200], 
    //   dark: orange[900], 
      contrastText: '#fff', 
    },
    error: {
      main: '#CA483F', 
    //   light: red[200],
    //   dark: red[900], 
      contrastText: '#fff',
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    },
    common: {
      black: '#000',
      white: '#fff',
    },
  },
  // components: {
  //   MuiButton: {
  //     defaultProps: {
  //       size: 'small',
  //       styleOverrides: {
  //         root: {
  //           height: '40px',
  //         },
  //       },
  //     },
  //   },
  //   MuiTextField: {
  //     defaultProps: {
  //       size: 'small',
  //       styleOverrides: {
  //         root: {
  //           height: '38px',
  //         },
  //       },
  //     },
  //   },
  // },
  overrides: {
    '@fullcalendar/react': {
      '.fc-view': {
        background: '#ffffff',
      },
    },
    '@mui/x-data-grid': {
      root: {
        '& .MuiDataGrid-cell:focus': {
          outline: 'none !important',
        },
      },
    },
  },
});

export default ardoviaTheme;
